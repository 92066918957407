<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-card>
                  <v-row class="mt-5">
                    <!-- Data download and print option  -->
                    <v-col cols="4">
                        <!-- <v-card-title class="pb-10 ">
                            <v-spacer></v-spacer>
                            <v-btn @click="exportTableToCSV('publish_products.csv')" text>
                              <v-icon class="pr-5" large color="black">mdi-download </v-icon>
                            </v-btn>
                            <v-btn text @click="print">
                              <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
                            </v-btn>
                        </v-card-title>                                                 -->
                    </v-col>
                        <!-- Date filtering  -->

                    <v-col cols="8" class="d-flex flex-row">
                      <v-spacer></v-spacer>
                      <v-col cols="2">
                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateto1" label="From Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                              v-on="on" dense style="padding:0px !important"></v-text-field>
                          </template>
                          <v-date-picker v-model="dateto1" @input="menu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="2">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateto" label="To Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                              v-on="on" dense style="padding:0px !important"></v-text-field>
                          </template>
                          <v-date-picker v-model="dateto" @input="menu2=false"></v-date-picker>
                        </v-menu>
                      </v-col>      
                      <v-btn text @click="seereport" class="mr-2 font-weight-bold" style="background: gray">
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-data-table
                    :headers="Publishheader"
                    :items="publishArray"
                    :search="searchseller"
                    class="elevation-1"
                    style="background: #f5f5f5"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    hide-default-footer
                    :server-items-length="totalItems1"
                  >
                    <template v-slot:top>
                      <v-card-title style="font-size: 26px">
                        Publishable Products
                        <v-spacer></v-spacer>
                        <div class="pr-4 pb-4 pt-2 d-flex ">
                          <div class="search g">
                              <v-text-field v-model="search_key" label="Search by product id" filled rounded   dense hide-details>
                              </v-text-field>
                          </div>
                          <div class="icon-search pr-4 pb-5 pt-2">
                              <v-btn icon style="background: red"><v-icon @click="searchHandle">mdi-magnify</v-icon>
                              </v-btn>                                                          
                          </div>                         
                        </div>
                      </v-card-title>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="viewDetail(item)"
                        >Detail</v-btn
                      >
                    </template>
                    <template v-slot:item.Publish="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="Publish(item)"
                      >
                        Publish</v-btn
                      >
                    </template>
                  </v-data-table>

                  <div class="d-flex justify-content-center mt-5">
                    <!-- <v-col cols="3" md="3"> -->
                    <div class="col-4">
                        Total {{ totalItems1 }} records
                    </div>
                    <!-- </v-col> -->
                    <!-- <v-col cols="3" md="3"> -->
                    <div class="col-4">
                        <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                        </v-select>                                                
                    </div>
                    <!-- </v-col> -->
                    <!-- <v-col cols="6" md="3" > -->
                    <div class="col-4">
                        <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                        </v-pagination>
                    </div>
                    <!-- </v-col> -->
                  </div> 
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-dialog
            v-model="dialog"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card
                  class="mx-auto mt-12"
                  elevation="0"
                  v-if="
                    !(
                      Object.keys(sellerPdetails).length === 0 &&
                      sellerPdetails.constructor === Object
                    )
                  "
                >
                  <v-row>
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                      v-if="sellerPdetails.images.length > 0"
                    >
                      <v-carousel-item
                        v-for="(slide, i) in sellerPdetails.images"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            sellerPdetails.id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Merchant Name :
                          <span class="font-weight-regular">{{
                            sellerPdetails.seller_name
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            sellerPdetails.title
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.sub_sub_category
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ sellerPdetails.brand }}
                          </span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular">{{
                            sellerPdetails.warranty
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Quantity :
                          <span class="font-weight-regular">{{
                            sellerPdetails.quantity
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"> Unit : {{ sellerPdetails.unit }} </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            sellerPdetails.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            sellerPdetails.shipping_country
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Discount Type :
                          <span class="font-weight-regular">{{
                            sellerPdetails.discount_type
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Discount Amount :
                          <span class="font-weight-regular">{{
                            sellerPdetails.discount_amount
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Discount Validity Till :
                          <span class="font-weight-regular">{{
                            sellerPdetails.discount_end_date
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Price :
                          <span class="font-weight-regular">
                            {{ sellerPdetails.new_price }}</span
                          >
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Old Price :
                          <span class="font-weight-regular">{{
                            sellerPdetails.old_price
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Point :
                          <span class="font-weight-regular">{{
                            sellerPdetails.point
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Point Validity Till :
                          <span>{{ sellerPdetails.point_end_date }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Properties : <span></span
                          >{{ sellerPdetails.properties }}
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ sellerPdetails.description }}
                      </v-col>

                      <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(itemfeatures,
                            index) in sellerPdetails.key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div
                          class="d-flex flex-row"
                          v-if="sellerPdetails.specification.colors.length > 0"
                        >
                          Color :
                          <p
                            class="ml-5"
                            v-for="(itemcolor, index) in sellerPdetails
                              .specification.colors"
                            :key="index"
                          >
                            {{ itemcolor }}
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        <div
                          class="d-flex flex-row"
                          v-if="sellerPdetails.specification.sizes.length > 0"
                        >
                          Size :

                          <p
                            class="ml-5"
                            v-for="(itemsize, index) in sellerPdetails
                              .specification.sizes"
                            :key="index"
                          >
                            {{ itemsize }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="red"
                      @click="closedialog(sellerPdetails)"
                      elevation="0"
                      >Cencal</v-btn
                    >
                    <v-btn
                      flot-right
                      color="success"
                      @click="Publish(sellerPdetails)"
                      elevation="0"
                      >Publish</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-row>
      <v-snackbar v-model="snackbar" centered :color="color">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>



<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    color: null,
    text: '',
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    editdialog: false,
    search_key: null,
    dateto: null,
    dateto1: null,
    menu2: false,
    menu: false,
    loading: true,
    searchseller: "",
    snackbar: false,

    Publishheader: [
      {
        text: "Product ID",
        align: "start",
        value: "id",
      },
      { text: "Product Title", value: "title" },
      { text: "Category ", value: "category" },
      { text: "Price", value: "new_price" },
      {
        text: "Status",
        value: "Publish",
        sortable: false,
      },
      //{ text: "Admin Confirmation", value: "adminaction", sortable: false },
      { text: "View", value: "actions", sortable: false },
      // { text: 'test', value: 'tes' },
      // { text: "", value: "data-table-expand" },
    ],

    publishArray: [],
    product_status: "Published",
    sellerPdetails: {},
  }),

  methods: {
    searchHandle(event){
      this.initialize();
    },
    handlePageSizeChange(event) {
      // console.log('event = ', event);
      this.perPage1 = (event);
      this.loading = true;
      this.initialize();
    },

    handlePageChange(value) {
      this.currentPage1 = value;
      this.loading = true;
      this.initialize();
      window.scrollTo(0, 0);
    },
    initialize() {
      let perPage ;
      if (this.perPage1 === 'All'){
        perPage = this.totalItems1;
      }
      else
      {
        perPage = this.perPage1;
      }
      this.loading = true;
      axios.get(`product/unpublished_products/?page=${this.currentPage1}&size=${perPage}&FromDate=${this.dateto1}&ToDate=${this.dateto}&search_key=${this.search_key}`).then((response) => {
        if (response.status === 200) {
          this.publishArray = response.data.results;
          this.totalItems1 = response.data.count;
          this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
          this.loading = false;
        }
      });
      // axios.get("product/unpublished_products/").then((response) => {
      //   if (response.data.success) {
      //     this.publishArray = response.data.data;
      //     this.loading = false;
      //   }
      // });
    },
    fromdate() { },
    seereport() {
      this.initialize();
    },
    todate() { },

    closedialog() {
      this.dialog = false;
    },

    viewDetail(item) {
      this.sellerPdetails = item;
      this.dialog = true;
    },

    Publish(item) {
      if (confirm("Do you want to Publish ?")) {
        axios
          .post(`product/publish_product/${item.id}/`, {
            product_status: "Published",
          })
          .then((response) => {
            if (response.data.success) {
              this.publishArray = this.publishArray.filter(
                (product) => product.id !== item.id
              );
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            alert("Something went wrong");
          });
      }
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>



<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>